<template>
  <div>
    <portal to="body-top">
      <div>
        <h3 class="text-center font-weight-bolder mb-2">
          {{ getWarehousePageTitle }} {{ getWarehousePageTitle === 'Fulfillment Orders' ? warehouseDatePicker.currentMonthTitle : '' }}
        </h3>
      </div>
    </portal>
    <div>
      <page-top :is-searchable="true" />
    </div>
    <router-view />
  </div>
</template>
<script>
import config from '../config'
import PageTop from '../components/view-top/PageTop.vue'

export default {
  components: { PageTop },
  computed: {
    warehouseDatePicker() {
      return this.$store.state[this.MODULE_NAME].warehouseDatePicker
    },
    getWarehousePageTitle() {
      return this.$store.state[this.MODULE_NAME].warehousePageTitle
    },
  },
  setup() {
    const { FULFILLMENT_ORDERS, MODULE_NAME } = config()

    return {
      MODULE_NAME,
      FULFILLMENT_ORDERS,
    }
  },
}

</script>
