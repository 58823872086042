<template>
  <div
    class="d-flex align-items-center"
    style="gap: 16px"
  >
    <span class="font-weight-bolder">
      {{ $t('Color Key:') }}
    </span>
    <div
      class="d-flex align-items-center"
      style="gap: 8px"
    >
      <div
        v-for="(colorKey, idx) in orderStatesAndStatuses"
        v-if="colorKey.status < 6"
        :key="idx"
        class="d-flex align-items-center"
        style="gap: 8px"
      >
        <span
          class="d-inline-block"
          :style="{ width:'15px', height:'15px', backgroundColor: colorKey.style.color }"
        />
        <span>
          {{ colorKey.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Components

// Configs
import config from '@/views/main/orders/config'

export default {
  name: 'StatusFilterColorKey',
  setup() {
    const { orderStatesAndStatuses } = config()

    return {
      orderStatesAndStatuses,
    }
  },
}
</script>
<style lang="scss" scoped>
.btn-custom {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #D9D9D9 !important;
  margin: 0;
  transition: all 0.2s ease;

  &--active {
    background-color: #646464 !important;
    transition: all 0.2s ease;
    & svg {
      fill: #fff !important;
    }
  }

  &--LPickIcon, &--LBoxIcon {
    & svg {
      position: relative;
      top: 3px;
    }
  }

  &--clear {
    background: transparent !important;
    & svg {
      fill: #D9D9D9;
    }

    &:hover {
      border-color: rgba(100, 100, 100, 0.8);
      & svg {
        fill: rgba(100, 100, 100, 0.8);
      }
    }
  }
}
</style>
<style lang="scss">
.paddingCustom {
  border: 1px dashed #dde3e7;
  border-radius: 0.35rem;
  height: 36px;
  min-width: 115px;

  & .btn {
    padding: 7px 15.5px !important;
  }

  .dropdown-item {
    padding: 0.2rem 1.28rem;
  }
}

.dropNewClass{
  outline: none;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #49454f;
  gap: 9px;
}
</style>
