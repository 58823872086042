<template>
  <div>
    <b-row>
      <b-col md="6">
        <auto-suggest-component :is-searchable="isSearchable" />
      </b-col>
      <b-col md="6">
        <div class="d-flex align-items-center justify-content-end">
          <status-filter-color-key />
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-1">
      <b-col md="6">
        <page-tabs :filter-tabs="filterTabs" />
      </b-col>
      <b-col md="6">
        <div class="d-flex align-items-center justify-content-end">
          <status-filter-tab />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import AutoSuggestComponent
from '@/views/main/warehouse/main-order/components/ready-components-use-as-copy/AutoSuggestComponent.vue'
import StatusFilterColorKey from '@/views/main/warehouse/main-order/components/use-as-global/StatusFilterColorKey.vue'
import PageTabs from '@/views/main/warehouse/main-order/components/view-top/PageTabs.vue'
import StatusFilterTab from '@/views/main/warehouse/main-order/components/ready-components-use-as-copy/StatusFilterTab.vue'
import config from '../../config'

export default {
  name: 'PageTop',
  components: {
    StatusFilterTab,
    PageTabs,
    StatusFilterColorKey,
    AutoSuggestComponent,
    BRow,
    BCol,
  },
  props: {
    isSearchable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      suggestions: [],
      searchQuery: null,
    }
  },
  computed: {
  },
  methods: {
  },

  setup() {
    const { MODULE_NAME, filterTabs } = config()

    return {
      MODULE_NAME,
      filterTabs,
    }
  },
}
</script>
